.main-container {
  text-align: center;
  padding: 6.35vh 5.2vw 0px 5.2vw;
}

.debt-clock-share-taxpayer-logo-container {
  margin: 50px 0 25px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.debt-clock-federal-container {
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 15px;
  padding: 0 0 20px 0;
}

.debt-clock-federal-text {
  color: var(--PRIMARY_RED);
  font-size: var(--HEADER_FONT_SIZE);
  background: white;
  margin-bottom: 0;
}

.debt-clock-federal-text-en {
  width: 39vw;
  margin-top: -4vh;
  margin-left: 48vw;
}

.debt-clock-federal-text-fr {
  margin-top: -4vh;
  width: 45vw;
  margin-left: 42vw;
}

.debt-clock-federal-text-cn {
  width: 24vw;
  margin-top: -5vh;
  margin-left: 62.5vw;
  font-weight: normal;
}

.debt-clock-federal-text-cn_yue {
  width: 24vw;
  margin-top: -5vh;
  margin-left: 62.5vw;
  font-weight: normal;
}

.debt-clock-federal-text-tl {
  width: 44vw;
  margin-top: -4vh;
  margin-left: 43vw;
}

.debt-clock-federal-text-pa {
  width: 25vw;
  margin-top: -5vh;
  margin-left: 61vw;
}

.debt-clock-share {
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 15px;
  padding: 0 0 20px 0;
}

.debt-clock-share-text {
  background: white;
  color: var(--PRIMARY_RED);
  margin-bottom: 0;
  font-size: var(--HEADER_FONT_SIZE);
}

.debt-clock-share-text-en {
  width: 20.8vw;
  margin-top: -40px;
  margin-left: 21vw;
}

.debt-clock-share-text-fr {
  margin-top: -40px;
  width: 19vw;
  margin-left: 23vw;
}

.debt-clock-share-text-cn {
  margin-top: -44px;
  width: 12.5vw;
  margin-left: 29vw;
  font-weight: normal;
}

.debt-clock-share-text-cn_yue {
  margin-top: -44px;
  width: 12.5vw;
  margin-left: 29vw;
  font-weight: normal;
}

.debt-clock-share-text-tl {
  width: 34vw;
  margin-top: -40px;
  margin-left: 8vw;
}

.debt-clock-share-text-pa {
  width: 13vw;
  margin-top: -45px;
  margin-left: 28vw;
}

.taxpayer-logo {
  margin: auto;
}

.logo-width {
  width: 40.25vw;
}

.supported-by-container {
  margin-top: 5.29vh;
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.supported-by-text {
  width: 31.25vw;
  margin: -25px 0px;
  background-color: var(--PRIMARY_RED);
  z-index: 10;
  position: relative;
  border-radius: 0 50px 50px 0;
  padding: 10px 2px;
  color: white;
  font-weight: 400;
  font-size: var(--SUB_HEADER_FONT_SIZE);
  letter-spacing: 6px;
}

.supported-by-text-cn {
  width: 25.25vw;
  letter-spacing: 15px;
}

.supported-by-text-cn_yue {
  width: 25.25vw;
  letter-spacing: 15px;
}

.supported-by-text-tl {
  width: 39vw;
}

.sign-petition-text-container {
  text-align: right;
}

.sign-petition-text {
  font-size: var(--HEADER_FONT_SIZE);
}

.sign-petition-bold {
  font-weight: bold;
}

/* 
  NOTE: App is specifically styled for LED screen resolution provided by Adtronics (except for styling for 1680px width)
  LED screen resolutions: 
  960w x 480h pixels
  704w x 480h pixels
  480w x 320h pixels
  480w x 240h pixels
*/

/* 
  Width of 1650px for testing purposes locally
*/
@media only screen and (max-width: 1680px) and (max-height: calc(0.7 * 100vw)) {
  .main-container {
    padding: 10vh 2.75vw 0px 2.75vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 10vh 0 8.36vh 0;
  }

  .debt-clock-federal-text-en {
    width: 38.75vw;
    margin-top: -4vh;
    margin-left: 52vw;
  }

  .debt-clock-federal-text-cn {
    margin-left: 67vw;
  }

  .debt-clock-federal-text-tl {
    width: 43.75vw;
    margin-top: -4vh;
    margin-left: 47vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4.5vh;
    margin-left: 23vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-cn {
    margin-left: 31vw;
  }

  .debt-clock-share-text-tl {
    width: 34vw;
    margin-top: -40px;
    margin-left: 9vw;
  }

  .supported-by-text {
    width: 35vw;
    letter-spacing: 4px;
    padding: 8px 3px;
    margin: -17px 0px;
  }
}

/*
  Aspect Ratio of 16:10 (Most commonly found on Macbooks) used for testing purposes
*/
@media (device-aspect-ratio) {
  .debt-clock-federal-text-en {
    width: 43vw;
    margin-top: -4vh;
    margin-left: 42.75vw;
  }

  .provincial-en-bc {
    width: 66vw;
    margin-left: 19.5vw;
  }

  .provincial-en-ab {
    width: 49.5vw;
    margin-left: 36.25vw;
  }

  .provincial-en-sk {
    width: 62vw;
    margin-left: 23.5vw;
  }

  .provincial-en-mb {
    width: 52vw;
    margin-left: 33.75vw;
  }

  .provincial-en-on {
    width: 49vw;
    margin-left: 36.75vw;
  }

  .provincial-en-qc {
    width: 49vw;
    margin-left: 36.75vw;
  }

  .provincial-en-nb {
    width: 63vw;
    margin-left: 22.75vw;
  }

  .provincial-en-ns {
    width: 56vw;
    margin-left: 29.5vw;
  }

  .provincial-en-nl {
    font-size: calc(var(--HEADER_FONT_SIZE) - 3px);
    width: 84vw;
    margin-left: 1.75vw;
  }

  .provincial-en-pe {
    width: 75vw;
    margin-left: 10.5vw;
  }

  .debt-clock-federal-text-fr {
    width: 50vw;
    margin-top: -4vh;
    margin-left: 36vw;
  }

  .provincial-fr-bc {
    width: 64vw;
    margin-left: 22vw;
  }

  .provincial-fr-ab {
    width: 36vw;
    margin-left: 50vw;
  }

  .provincial-fr-mb {
    width: 36vw;
    margin-left: 50vw;
  }

  .provincial-fr-on {
    width: 35vw;
    margin-left: 51vw;
  }

  .provincial-fr-qc {
    width: 32vw;
    margin-left: 54vw;
  }

  .provincial-fr-nb {
    width: 56vw;
    margin-left: 30vw;
  }

  .provincial-fr-ns {
    width: 54vw;
    margin-left: 32vw;
  }

  .provincial-fr-nl {
    width: 66vw;
    margin-left: 20vw;
  }

  .provincial-fr-pe {
    width: 63vw;
    margin-left: 23vw;
  }

  .debt-clock-federal-text-cn {
    width: 24vw;
    margin-top: -4vh;
    margin-left: 61.5vw;
  }

  .provincial-cn-bc {
    width: 43vw;
    margin-left: 42.5vw;
  }

  .provincial-cn-ab {
    width: 34vw;
    margin-left: 51.5vw;
  }

  .provincial-cn-sk {
    width: 37vw;
    margin-left: 48.5vw;
  }

  .provincial-cn-mb {
    width: 34vw;
    margin-left: 51.5vw;
  }

  .provincial-cn-on {
    width: 31vw;
    margin-left: 54.5vw;
  }

  .provincial-cn-qc {
    width: 31vw;
    margin-left: 54.5vw;
  }

  .provincial-cn-nb {
    width: 36.5vw;
    margin-left: 49vw;
  }

  .provincial-cn-ns {
    width: 33vw;
    margin-left: 52.5vw;
  }

  .provincial-cn-nl {
    width: 47vw;
    margin-left: 38.5vw;
  }

  .provincial-cn-pe {
    width: 40vw;
    margin-left: 45.5vw;
  }

  .debt-clock-federal-text-cn_yue {
    width: 23.5vw;
    margin-top: -4vh;
    margin-left: 63vw;
  }

  .provincial-cn_yue-bc {
    width: 43vw;
    margin-left: 43.5vw;
  }

  .provincial-cn_yue-ab {
    width: 30vw;
    margin-left: 56.5vw;
  }

  .provincial-cn_yue-sk {
    width: 36.5vw;
    margin-left: 50vw;
  }

  .provincial-cn_yue-mb {
    width: 33.5vw;
    margin-left: 53vw;
  }

  .provincial-cn_yue-on {
    width: 30vw;
    margin-left: 56.5vw;
  }

  .provincial-cn_yue-qc {
    width: 30vw;
    margin-left: 56.5vw;
  }

  .provincial-cn_yue-nb {
    width: 36.5vw;
    margin-left: 50vw;
  }

  .provincial-cn_yue-ns {
    width: 33.5vw;
    margin-left: 53vw;
  }

  .provincial-cn_yue-nl {
    width: 47vw;
    margin-left: 39.5vw;
  }

  .provincial-cn_yue-pe {
    width: 40vw;
    margin-left: 46.5vw;
  }

  .debt-clock-federal-text-tl {
    width: 49vw;
    margin-top: -3.5vh;
    margin-left: 37vw;
  }

  .provincial-tl-bc {
    width: 76.5vw;
    margin-left: 9.75vw;
  }

  .provincial-tl-ab {
    width: 60vw;
    margin-left: 26.25vw;
  }

  .provincial-tl-sk {
    width: 72vw;
    margin-left: 14.25vw;
  }

  .provincial-tl-mb {
    width: 62vw;
    margin-left: 24.25vw;
  }

  .provincial-tl-on {
    width: 59.5vw;
    margin-left: 26.75vw;
  }

  .provincial-tl-qc {
    width: 59vw;
    margin-left: 27.25vw;
  }

  .provincial-tl-nb {
    width: 74vw;
    margin-left: 12.25vw;
  }

  .provincial-tl-ns {
    width: 67vw;
    margin-left: 19.25vw;
  }

  .provincial-tl-nl {
    font-size: calc(var(--HEADER_FONT_SIZE) - 8px);
    width: 85vw;
    margin-left: 1.25vw;
  }

  .provincial-tl-pe {
    width: 85vw;
    margin-left: 1.25vw;
  }

  .debt-clock-federal-text-pa {
    width: 28vw;
    margin-top: -4vh;
    margin-left: 58.5vw;
  }

  .provincial-pa-bc {
    width: 40vw;
    margin-left: 46.5vw;
  }

  .provincial-pa-ab {
    width: 33vw;
    margin-left: 53.5vw;
  }

  .provincial-pa-sk {
    width: 33vw;
    margin-left: 53.5vw;
  }

  .provincial-pa-mb {
    width: 30vw;
    margin-left: 56.5vw;
  }

  .provincial-pa-on {
    width: 30vw;
    margin-left: 56.5vw;
  }

  .provincial-pa-qc {
    width: 30vw;
    margin-left: 56.5vw;
  }

  .provincial-pa-nb {
    width: 39vw;
    margin-left: 47.5vw;
  }

  .provincial-pa-ns {
    width: 36vw;
    margin-left: 50.5vw;
  }

  .provincial-pa-nl {
    width: 53vw;
    margin-left: 33.5vw;
  }

  .provincial-pa-pe {
    width: 43vw;
    margin-left: 43.5vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4vh;
    margin-left: 18vw;
    width: 22.75vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -4vh;
    margin-left: 20vw;
    width: 21vw;
  }

  .debt-clock-share-text-cn {
    margin-top: -4vh;
    margin-left: 26.5vw;
    width: 14vw;
  }

  .debt-clock-share-text-cn_yue {
    margin-top: -4vh;
    margin-left: 28vw;
    width: 13.5vw;
  }

  .debt-clock-share-text-tl {
    margin-top: -3.5vh;
    margin-left: 3.75vw;
    width: 37.5vw;
  }

  .debt-clock-share-text-pa {
    margin-top: -4vh;
    margin-left: 26.5vw;
    width: 15vw;
  }

  .ticker-federal {
    margin-left: 0.85vw;
  }

  .ticker-provincial-bc,
  .ticker-provincial-on,
  .ticker-provincial-qc {
    margin-left: 7.25vw;
  }

  .ticker-provincial-ab,
  .ticker-provincial-sk,
  .ticker-provincial-mb,
  .ticker-provincial-nb,
  .ticker-provincial-ns,
  .ticker-provincial-nl {
    margin-left: 12.25vw;
  }

  .ticker-provincial-pe {
    margin-left: 17.25vw;
  }
}

/* 
  Width of 1650px for testing purposes locally
*/
@media only screen and (max-width: 1680px) and (max-height: calc(0.51 * 100vw)) {
  .main-container {
    padding: 6.47vh 2.75vw 0px 2.75vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 6.47vh 0 0 0;
  }

  .debt-clock-federal-text-en {
    width: 38.75vw;
    margin-top: -4vh;
    margin-left: 52vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -4vh;
    width: 48vw;
    margin-left: 43.5vw;
  }

  .debt-clock-federal-text-pa {
    width: 25vw;
    margin-top: -5vh;
    margin-left: 66vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4.5vh;
    margin-left: 23vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -4.5vh;
    margin-left: 22.75vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-pa {
    width: 13vw;
    margin-top: -45px;
    margin-left: 30.5vw;
  }

  .supported-by-text {
    width: 35vw;
    letter-spacing: 4px;
    padding: 8px 3px;
    margin: -17px 0px;
  }
}

/* 
  For LED screen size of 960w x 480h pixels
*/
@media only screen and (max-width: 960px) {
  .main-container {
    padding: 35px 25px 0px 25px;
  }

  .debt-clock-federal-container {
    padding: 0 0 0 0;
  }

  .debt-clock-share {
    padding: 0 0 0 0;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 25px 0 0px 0;
  }

  .debt-clock-federal-text-en {
    width: 41.5vw;
    margin-top: -4vh;
    margin-left: 50.25vw;
  }

  .ticker-federal {
    margin-left: 3.75vw;
  }

  .ticker-provincial-bc,
  .ticker-provincial-on,
  .ticker-provincial-qc {
    margin-left: 10vw;
  }

  .ticker-provincial-ab,
  .ticker-provincial-sk,
  .ticker-provincial-mb,
  .ticker-provincial-nb,
  .ticker-provincial-ns,
  .ticker-provincial-nl {
    margin-left: 15vw;
  }

  .ticker-provincial-pe {
    margin-left: 20vw;
  }

  .provincial-en-bc {
    width: 64vw;
    margin-left: 28vw;
  }

  .provincial-en-ab {
    width: 47vw;
    margin-left: 45vw;
  }

  .provincial-en-sk {
    width: 59vw;
    margin-left: 33vw;
  }

  .provincial-en-mb {
    width: 50vw;
    margin-left: 42vw;
  }

  .provincial-en-on {
    width: 47vw;
    margin-left: 45vw;
  }

  .provincial-en-qc {
    width: 46vw;
    margin-left: 46vw;
  }

  .provincial-en-nb {
    width: 60.5vw;
    margin-left: 31.5vw;
  }

  .provincial-en-ns {
    width: 54.5vw;
    margin-left: 37.5vw;
  }

  .provincial-en-nl {
    font-size: var(--HEADER_FONT_SIZE);
    width: 86.25vw;
    margin-left: 5.5vw;
  }

  .provincial-en-pe {
    width: 72vw;
    margin-left: 20vw;
  }

  .debt-clock-federal-text-fr {
    width: 48vw;
    margin-left: 43.5vw;
    margin-top: -4vh;
  }

  .provincial-fr-bc {
    width: 61.5vw;
    margin-left: 30vw;
  }

  .provincial-fr-ab {
    width: 33.75vw;
    margin-left: 57.5vw;
  }

  .provincial-fr-sk {
    width: 48vw;
    margin-left: 43.25vw;
  }

  .provincial-fr-mb {
    width: 34vw;
    margin-left: 57.25vw;
  }

  .provincial-fr-on {
    width: 34vw;
    margin-left: 57.25vw;
  }

  .provincial-fr-qc {
    width: 30.5vw;
    margin-left: 60.75vw;
  }

  .provincial-fr-nb {
    width: 53.5vw;
    margin-left: 37.75vw;
  }

  .provincial-fr-ns {
    width: 52.75vw;
    margin-left: 38.5vw;
  }

  .provincial-fr-nl {
    width: 63vw;
    margin-left: 28.25vw;
  }

  .provincial-fr-pe {
    width: 60.5vw;
    margin-left: 30.75vw;
  }

  .debt-clock-federal-text-cn {
    width: 22.75vw;
    margin-top: -5.25vh;
    margin-left: 68.8vw;
  }

  .provincial-cn-bc {
    width: 41.5vw;
    margin-left: 50vw;
  }

  .provincial-cn-ab {
    width: 32.25vw;
    margin-left: 59.25vw;
  }

  .provincial-cn-sk {
    width: 35.25vw;
    margin-left: 56.25vw;
  }

  .provincial-cn-mb {
    width: 32vw;
    margin-left: 59.75vw;
  }

  .provincial-cn-on {
    width: 29.25vw;
    margin-left: 62.5vw;
  }

  .provincial-cn-qc {
    width: 29vw;
    margin-left: 62.5vw;
  }

  .provincial-cn-nb {
    width: 35.5vw;
    margin-left: 56.25vw;
  }

  .provincial-cn-ns {
    width: 32vw;
    margin-left: 59.5vw;
  }

  .provincial-cn-nl {
    width: 44vw;
    margin-left: 47.5vw;
  }

  .provincial-cn-pe {
    width: 38.25vw;
    margin-left: 53.5vw;
  }

  .debt-clock-federal-text-cn_yue {
    width: 22.75vw;
    margin-top: -5.25vh;
    margin-left: 68.8vw;
  }

  .provincial-cn_yue-bc {
    width: 41.25vw;
    margin-left: 50.5vw;
  }

  .provincial-cn_yue-ab {
    width: 28.75vw;
    margin-left: 62.75vw;
  }

  .provincial-cn_yue-sk {
    width: 35.5vw;
    margin-left: 56vw;
  }

  .provincial-cn_yue-mb {
    width: 32.25vw;
    margin-left: 59.5vw;
  }

  .provincial-cn_yue-on {
    width: 29.25vw;
    margin-left: 62.25vw;
  }

  .provincial-cn_yue-qc {
    width: 28.75vw;
    margin-left: 62.75vw;
  }

  .provincial-cn_yue-nb {
    width: 35vw;
    margin-left: 56.75vw;
  }

  .provincial-cn_yue-ns {
    width: 32vw;
    margin-left: 59.75vw;
  }

  .provincial-cn_yue-nl {
    width: 45vw;
    margin-left: 46.75vw;
  }

  .provincial-cn_yue-pe {
    width: 38.25vw;
    margin-left: 53.5vw;
  }

  .debt-clock-federal-text-tl {
    width: 46.75vw;
    margin-top: -4vh;
    margin-left: 44.5vw;
  }

  .provincial-tl-bc {
    width: 73.25vw;
    margin-left: 18vw;
  }

  .provincial-tl-ab {
    width: 57vw;
    margin-left: 34.25vw;
  }

  .provincial-tl-sk {
    width: 69vw;
    margin-left: 22.25vw;
  }

  .provincial-tl-mb {
    width: 60vw;
    margin-left: 31.25vw;
  }

  .provincial-tl-on {
    width: 57vw;
    margin-left: 34.25vw;
  }

  .provincial-tl-qc {
    width: 56.5vw;
    margin-left: 34.75vw;
  }

  .provincial-tl-nb {
    width: 70.75vw;
    margin-left: 20.75vw;
  }

  .provincial-tl-ns {
    width: 64vw;
    margin-left: 27.25vw;
  }

  .provincial-tl-nl {
    font-size: calc(var(--HEADER_FONT_SIZE) - 2.25px);
    width: 88.5vw;
    margin-left: 2.75vw;
  }

  .provincial-tl-pe {
    width: 82vw;
    margin-left: 9.25vw;
  }

  .debt-clock-federal-text-pa {
    width: 27vw;
    margin-left: 63vw;
  }

  .provincial-pa-bc {
    width: 37.75vw;
    margin-left: 52.25vw;
  }

  .provincial-pa-ab {
    width: 30.75vw;
    margin-left: 59.25vw;
  }

  .provincial-pa-sk {
    width: 31.5vw;
    margin-left: 58.5vw;
  }

  .provincial-pa-mb {
    width: 29.25vw;
    margin-left: 61vw;
  }

  .provincial-pa-on {
    width: 29.5vw;
    margin-left: 60.5vw;
  }

  .provincial-pa-qc {
    width: 29.5vw;
    margin-left: 60.75vw;
  }

  .provincial-pa-nb {
    width: 37.75vw;
    margin-left: 52.25vw;
  }

  .provincial-pa-ns {
    width: 35vw;
    margin-left: 55.25vw;
  }

  .provincial-pa-nl {
    width: 51.5vw;
    margin-left: 38.5vw;
  }

  .provincial-pa-pe {
    width: 40.5vw;
    margin-left: 49.5vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4.5vh;
    margin-left: 23vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -4.5vh;
    margin-left: 23.75vw;
    width: 20vw;
  }

  .debt-clock-share-text-cn {
    margin-top: -4.75vh;
    margin-left: 30.75vw;
    width: 13.3vw;
  }

  .debt-clock-share-text-cn_yue {
    margin-top: -4.75vh;
    margin-left: 30.75vw;
    width: 13.3vw;
  }

  .debt-clock-share-text-tl {
    margin-top: -4.5vh;
    margin-left: 7.25vw;
    width: 36.5vw;
  }

  .debt-clock-share-text-pa {
    margin-top: -5.5vh;
    margin-left: 28vw;
    width: 14.5vw;
  }

  .supported-by-text {
    width: 35vw;
    letter-spacing: 4px;
    padding: 8px 3px;
    margin: -18px 0px;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 15px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 15px;
  }

  .supported-by-text-tl {
    width: 40vw;
  }

  .supported-by-text-pa {
    width: 30vw;
  }
}

/* 
  For LED screen size of 704h x 480w pixels
*/
@media only screen and (max-width: 704px) {
  .main-container {
    padding: 40px 15px 0px 15px;
  }

  .debt-clock-federal-container {
    padding: 35px 0 12.5px 0;
  }

  .debt-clock-federal-text-en {
    width: 42vw;
    margin-top: -10.5vh;
    margin-left: 50vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -11.5vh;
    width: 48vw;
    margin-left: 43.5vw;
  }

  .debt-clock-federal-text-cn {
    margin-top: -12vh;
    width: 23vw;
    margin-left: 68.5vw;
  }

  .debt-clock-federal-text-cn_yue {
    margin-top: -12vh;
    width: 23vw;
    margin-left: 68.5vw;
  }

  .debt-clock-federal-text-tl {
    width: 46.75vw;
    margin-top: -11.5vh;
    margin-left: 44.5vw;
  }

  .debt-clock-federal-text-pa {
    width: 27vw;
    margin-top: -12vh;
    margin-left: 64vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 30px 0 10px 0;
  }

  .debt-clock-share {
    padding: 30px 0 12.5px 0;
  }

  .debt-clock-share-text-en {
    width: 22vw;
    margin-top: -10.5vh;
    margin-left: 21.75vw;
    font-size: var(--HEADER_FONT_SIZE);
  }

  .debt-clock-share-text-fr {
    margin-left: 23.25vw;
    width: 20vw;
    margin-top: -11.5vh;
  }

  .debt-clock-share-text-cn {
    margin-left: 29.75vw;
    width: 13.75vw;
    margin-top: -12vh;
  }

  .debt-clock-share-text-cn_yue {
    margin-left: 29.75vw;
    width: 13.75vw;
    margin-top: -12vh;
  }

  .debt-clock-share-text-tl {
    width: 36.5vw;
    margin-top: -11.5vh;
    margin-left: 7vw;
    font-size: var(--HEADER_FONT_SIZE);
  }

  .debt-clock-share-text-pa {
    width: 14.5vw;
    margin-top: -12vh;
    margin-left: 28.75vw;
  }

  .sign-petition-text {
    font-size: var(--HEADER_FONT_SIZE);
  }

  .supported-by-text {
    width: 35vw;
    font-size: var(--SUB_HEADER_FONT_SIZE);
    letter-spacing: 1px;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-tl {
    width: 45vw;
  }

  .supported-by-text-pa {
    letter-spacing: 3px;
  }
}

/* 
  For LED screen size of 640h x 320w pixels
*/
@media only screen and (max-width: 640px) {
  .main-container {
    padding: 20px 15px 0px 15px;
  }

  .debt-clock-federal-container {
    padding: 35px 0 12.5px 0;
  }

  .debt-clock-federal-text-en {
    width: 35.5vw;
    margin-top: -15vh;
    margin-left: 55vw;
  }

  .ticker-federal {
    margin-left: 6.75vw;
  }

  .ticker-provincial-bc,
  .ticker-provincial-on,
  .ticker-provincial-qc {
    margin-left: 12.75vw;
  }

  .ticker-provincial-ab,
  .ticker-provincial-sk,
  .ticker-provincial-mb,
  .ticker-provincial-nb,
  .ticker-provincial-ns,
  .ticker-provincial-nl {
    margin-left: 17.25vw;
  }

  .ticker-provincial-pe {
    margin-left: 22vw;
  }

  .provincial-en-bc {
    width: 54vw;
    margin-left: 36.75vw;
  }

  .provincial-en-ab {
    width: 40.5vw;
    margin-left: 50vw;
  }

  .provincial-en-sk {
    width: 50vw;
    margin-left: 40.5vw;
  }

  .provincial-en-mb {
    width: 43vw;
    margin-left: 47.75vw;
  }

  .provincial-en-on {
    width: 40vw;
    margin-left: 50.75vw;
  }

  .provincial-en-qc {
    width: 39.5vw;
    margin-left: 51vw;
  }

  .provincial-en-nb {
    width: 52vw;
    margin-left: 38.75vw;
  }

  .provincial-en-ns {
    width: 46.5vw;
    margin-left: 44vw;
  }

  .provincial-en-nl {
    font-size: var(--HEADER_FONT_SIZE);
    width: 74vw;
    margin-left: 16.75vw;
  }

  .provincial-en-pe {
    width: 62vw;
    margin-left: 28.5vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -15vh;
    width: 41vw;
    margin-left: 48.5vw;
  }

  .provincial-fr-bc {
    width: 53vw;
    margin-left: 37vw;
  }

  .provincial-fr-ab {
    width: 29vw;
    margin-left: 61vw;
  }

  .provincial-fr-sk {
    width: 41vw;
    margin-left: 49vw;
  }

  .provincial-fr-mb {
    width: 29.75vw;
    margin-left: 60.25vw;
  }

  .provincial-fr-on {
    width: 29vw;
    margin-left: 61vw;
  }

  .provincial-fr-qc {
    width: 26vw;
    margin-left: 64vw;
  }

  .provincial-fr-nb {
    width: 46vw;
    margin-left: 44vw;
  }

  .provincial-fr-ns {
    width: 45vw;
    margin-left: 45vw;
  }

  .provincial-fr-nl {
    width: 54.5vw;
    margin-left: 35.75vw;
  }

  .provincial-fr-pe {
    width: 52.25vw;
    margin-left: 37.75vw;
  }

  .debt-clock-federal-text-cn {
    margin-top: -15.75vh;
    width: 19.5vw;
    margin-left: 69.5vw;
  }

  .provincial-cn-bc {
    width: 35.5vw;
    margin-left: 53.75vw;
  }

  .provincial-cn-ab {
    width: 27.5vw;
    margin-left: 61.5vw;
  }

  .provincial-cn-sk {
    width: 30vw;
    margin-left: 59vw;
  }

  .provincial-cn-mb {
    width: 27.5vw;
    margin-left: 61.75vw;
  }

  .provincial-cn-on {
    width: 25vw;
    margin-left: 64vw;
  }

  .provincial-cn-qc {
    width: 25vw;
    margin-left: 64.25vw;
  }

  .provincial-cn-nb {
    width: 30vw;
    margin-left: 59.25vw;
  }

  .provincial-cn-ns {
    width: 27.5vw;
    margin-left: 61.5vw;
  }

  .provincial-cn-nl {
    width: 38vw;
    margin-left: 51vw;
  }

  .provincial-cn-pe {
    width: 33vw;
    margin-left: 56vw;
  }

  .debt-clock-federal-text-cn_yue {
    margin-top: -15.5vh;
    width: 19vw;
    margin-left: 69.5vw;
  }

  .provincial-cn_yue-bc {
    width: 35.5vw;
    margin-left: 53.5vw;
  }

  .provincial-cn_yue-ab {
    width: 25vw;
    margin-left: 64vw;
  }

  .provincial-cn_yue-sk {
    width: 30vw;
    margin-left: 59vw;
  }

  .provincial-cn_yue-mb {
    width: 27.5vw;
    margin-left: 61.5vw;
  }

  .provincial-cn_yue-on {
    width: 25.5vw;
    margin-left: 63.5vw;
  }

  .provincial-cn_yue-qc {
    width: 25vw;
    margin-left: 64vw;
  }

  .provincial-cn_yue-nb {
    width: 30vw;
    margin-left: 59vw;
  }

  .provincial-cn_yue-ns {
    width: 27.5vw;
    margin-left: 61.5vw;
  }

  .provincial-cn_yue-nl {
    width: 38vw;
    margin-left: 51vw;
  }

  .provincial-cn_yue-pe {
    width: 33vw;
    margin-left: 56vw;
  }

  .debt-clock-federal-text-tl {
    width: 39.75vw;
    margin-top: -14.75vh;
    margin-left: 50vw;
  }

  .provincial-tl-bc {
    width: 63vw;
    margin-left: 26.75vw;
  }

  .provincial-tl-ab {
    width: 48.25vw;
    margin-left: 41.25vw;
  }

  .provincial-tl-sk {
    width: 59vw;
    margin-left: 30.75vw;
  }

  .provincial-tl-mb {
    width: 51vw;
    margin-left: 38.75vw;
  }

  .provincial-tl-on {
    width: 49vw;
    margin-left: 40.75vw;
  }

  .provincial-tl-qc {
    width: 48vw;
    margin-left: 41.5vw;
  }

  .provincial-tl-nb {
    width: 60.5vw;
    margin-left: 29vw;
  }

  .provincial-tl-ns {
    width: 55vw;
    margin-left: 34.75vw;
  }

  .provincial-tl-nl {
    font-size: var(--HEADER_FONT_SIZE);
    width: 82vw;
    margin-left: 7.5vw;
  }

  .provincial-tl-pe {
    width: 70vw;
    margin-left: 19.5vw;
  }

  .debt-clock-federal-text-pa {
    width: 22.5vw;
    margin-top: -16vh;
    margin-left: 67vw;
  }

  .provincial-pa-bc {
    width: 32vw;
    margin-left: 57.75vw;
  }

  .provincial-pa-ab {
    width: 26vw;
    margin-left: 63.5vw;
  }

  .provincial-pa-sk {
    width: 26.5vw;
    margin-left: 63.25vw;
  }

  .provincial-pa-mb {
    width: 24vw;
    margin-left: 65.5vw;
  }

  .provincial-pa-on {
    width: 25vw;
    margin-left: 64.75vw;
  }

  .provincial-pa-qc {
    width: 25vw;
    margin-left: 64.5vw;
  }

  .provincial-pa-nb {
    width: 31.5vw;
    margin-left: 58vw;
  }

  .provincial-pa-ns {
    width: 30vw;
    margin-left: 59.75vw;
  }

  .provincial-pa-nl {
    width: 44vw;
    margin-left: 45.5vw;
  }

  .provincial-pa-pe {
    width: 34vw;
    margin-left: 55.5vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 15px 0 0 0;
  }

  .debt-clock-share {
    padding: 30px 0 12.5px 0;
  }

  .debt-clock-share-text-en {
    width: 19vw;
    margin-top: -13.75vh;
    margin-left: 23.75vw;
    font-size: var(--HEADER_FONT_SIZE);
  }

  .debt-clock-share-text-fr {
    margin-left: 24.75vw;
    width: 17.5vw;
    margin-top: -13.5vh;
  }

  .debt-clock-share-text-cn {
    margin-left: 29.75vw;
    width: 11.5vw;
    margin-top: -14vh;
  }

  .debt-clock-share-text-cn_yue {
    margin-left: 29.75vw;
    width: 11.5vw;
    margin-top: -14vh;
  }

  .debt-clock-share-text-tl {
    width: 31vw;
    margin-top: -13.5vh;
    margin-left: 10.75vw;
    font-size: var(--HEADER_FONT_SIZE);
  }

  .debt-clock-share-text-pa {
    width: 11.75vw;
    margin-top: -14vh;
    margin-left: 30vw;
  }

  .sign-petition-text {
    font-size: var(--HEADER_FONT_SIZE);
  }

  .supported-by-text {
    width: 35vw;
    font-size: var(--SUB_HEADER_FONT_SIZE);
    letter-spacing: 1px;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-tl {
    width: 40vw;
  }

  .supported-by-text-pa {
    letter-spacing: 3px;
  }
}

/* 
  For LED screen size of 480w x 320h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 320px) {
  .main-container {
    padding: 25px 15px 0px 15px;
  }

  .debt-clock-federal-container {
    padding: 35px 0 6px 0;
  }

  .debt-clock-federal-text-en {
    width: 43vw;
    margin-top: -15vh;
    margin-left: 45.5vw;
  }

  .debt-clock-federal-text-fr {
    width: 51vw;
    margin-left: 37vw;
    margin-top: -16.5vh;
  }

  .debt-clock-federal-text-cn {
    width: 23.5vw;
    margin-left: 65vw;
    margin-top: -16.5vh;
  }

  .debt-clock-federal-text-cn_yue {
    width: 23.5vw;
    margin-left: 65vw;
    margin-top: -16.5vh;
  }

  .debt-clock-federal-text-tl {
    width: 48.5vw;
    margin-top: -16.5vh;
    margin-left: 39.75vw;
  }

  .debt-clock-federal-text-pa {
    width: 28vw;
    margin-top: -17vh;
    margin-left: 60.5vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 25px 0 7.5px 0;
  }

  .debt-clock-share {
    padding: 40px 0 6px 0;
  }

  .debt-clock-share-text-en {
    width: 23vw;
    margin-top: -16.5vh;
    margin-left: 18vw;
  }

  .debt-clock-share-text-fr {
    width: 21.75vw;
    margin-top: -16.5vh;
    margin-left: 19vw;
  }

  .debt-clock-share-text-cn {
    width: 14vw;
    margin-top: -16.5vh;
    margin-left: 27vw;
  }

  .debt-clock-share-text-cn_yue {
    width: 14vw;
    margin-top: -16.5vh;
    margin-left: 27vw;
  }

  .debt-clock-share-text-tl {
    width: 38.25vw;
    margin-top: -16.5vh;
    margin-left: 3.25vw;
  }

  .debt-clock-share-text-pa {
    width: 15vw;
    margin-top: -17vh;
    margin-left: 26.25vw;
  }

  .supported-by-text {
    width: 40vw;
    letter-spacing: 1.5px;
    padding: 6px 2px;
    margin: -15px 0px;
  }

  .supported-by-text-tl {
    width: 50vw;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-pa {
    letter-spacing: 3px;
  }
}

/* 
  For LED screen size of 480w x 240h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 240px) {
  .main-container {
    padding: 16px 15px 0px 15px;
  }

  .debt-clock-federal-container {
    padding: 27.5px 0 6px 0;
  }

  .debt-clock-federal-text-en {
    margin-top: -16.25vh;
    margin-left: 51.75vw;
    width: 36.5vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -21.25vh;
    width: 41.75vw;
    margin-left: 45.75vw;
  }

  .debt-clock-federal-text-cn {
    width: 20vw;
    margin-top: -21.75vh;
    margin-left: 68vw;
  }

  .debt-clock-federal-text-cn_yue {
    width: 20vw;
    margin-top: -21.75vh;
    margin-left: 68vw;
  }

  .debt-clock-federal-text-tl {
    margin-top: -21.25vh;
    margin-left: 48.5vw;
    width: 40.75vw;
  }

  .debt-clock-federal-text-pa {
    margin-top: -22vh;
    margin-left: 64.75vw;
    width: 23.5vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 12px 0 0px 0;
  }

  .debt-clock-share-text-en {
    margin-top: -21.25vh;
    margin-left: 22.5vw;
    width: 19vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -21.25vh;
    margin-left: 22.75vw;
    width: 17.75vw;
  }

  .debt-clock-share-text-cn {
    margin-top: -21.75vh;
    margin-left: 29vw;
    width: 12vw;
  }

  .debt-clock-share-text-cn_yue {
    margin-top: -21.75vh;
    margin-left: 29vw;
    width: 12vw;
  }

  .debt-clock-share-text-tl {
    margin-top: -21.25vh;
    margin-left: 10.75vw;
    width: 31.5vw;
  }

  .debt-clock-share-text-pa {
    margin-top: -22vh;
    margin-left: 28.65vw;
    width: 12.75vw;
  }

  .supported-by-text {
    padding: 3px 1px;
    margin: -10px 0px;
  }
}
