body {
  font-size: 92px;
  background: white;
  text-align: center;
  font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
  font-weight: 450;
}

.debt-clock-container {
  height: 1em;
  padding-top: 10px;
  padding-bottom: 35px;
}

.digit {
  display: inline-block;
  height: 1em;
  width: 0.59em;
  overflow: hidden;

  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 0.075em 0.12em;
  margin: 3.5px;
  background-color: black;
  color: white;
  border-radius: 0.1em 0.1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dollar-sign-container {
  display: inline-block;
  height: 1em;
  width: 0.59em;
  overflow: hidden;
  padding: 0.075em 0.12em;
  margin: 3.5px;
  color: var(--PRIMARY_RED);
}

.dollar-sign {
  line-height: 1em;
}

.non-digit {
  display: inline-block;
  height: 1em;
  color: var(--PRIMARY_RED);
}

.digit-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  line-height: 1em;
  animation-name: slide-digit;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform;
}

.digit-container-slide-down {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  line-height: 1em;
  animation-name: slide-digit-down;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform;
}

.digit-container-animation-pause {
  line-height: 1em;
  animation-name: slide-digit-with-pause;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.digit-container-animation-pause-slide-down {
  line-height: 1em;
  animation-name: slide-digit-with-pause-slide-down;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes slide-digit {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10em);
  }
}

@keyframes slide-digit-down {
  from {
    transform: translateY(-10em);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-digit-with-pause {
  0%,
  5%,
  9% {
    transform: translateY(0em);
  }
  10%,
  15%,
  17% {
    transform: translateY(-1em);
  }
  20%,
  25%,
  27% {
    transform: translateY(-2em);
  }
  30%,
  35%,
  37% {
    transform: translateY(-3em);
  }
  40%,
  45%,
  47% {
    transform: translateY(-4em);
  }
  50%,
  55%,
  57% {
    transform: translateY(-5em);
  }
  60%,
  65%,
  67% {
    transform: translateY(-6em);
  }
  70%,
  75%,
  77% {
    transform: translateY(-7em);
  }
  80%,
  85%,
  87% {
    transform: translateY(-8em);
  }
  90%,
  95%,
  97% {
    transform: translateY(-9em);
  }
  100% {
    transform: translateY(-10em);
  }
}

@keyframes slide-digit-with-pause-slide-down {
  0%,
  5%,
  9% {
    transform: translateY(-10em);
  }
  10%,
  15%,
  17% {
    transform: translateY(-9em);
  }
  20%,
  25%,
  27% {
    transform: translateY(-8em);
  }
  30%,
  35%,
  37% {
    transform: translateY(-7em);
  }
  40%,
  45%,
  47% {
    transform: translateY(-6em);
  }
  50%,
  55%,
  57% {
    transform: translateY(-5em);
  }
  60%,
  65%,
  67% {
    transform: translateY(-4em);
  }
  70%,
  75%,
  77% {
    transform: translateY(-3em);
  }
  80%,
  85%,
  87% {
    transform: translateY(-2em);
  }
  90%,
  95%,
  97% {
    transform: translateY(-1em);
  }
  100% {
    transform: translateY(0em);
  }
}

/* 
  For LED screen size of 960w x 320h pixels
*/
@media only screen and (max-width: 960px) {
  body {
    font-size: 48px;
  }

  .digit {
    margin: 2.75px;
  }

  .debt-clock-container {
    padding-top: 5px;
  }

  .dollar-sign-container {
    width: 0.45em;
  }
}

@media only screen and (max-width: 704px) {
  body {
    font-size: 37.5px;
  }

  .digit {
    margin: 1px;
  }

  .debt-clock-container {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  body {
    font-size: 31px;
  }

  .digit {
    margin: 1px;
  }

  /* .debt-clock-container {
    padding-bottom: 15px;
  } */
}

/* 
  For LED screen width of 480w pixels
*/
@media only screen and (max-width: 480px) {
  body {
    font-size: 23px;
  }

  .digit {
    margin: 0.75px;
  }

  .dollar-sign-container {
    width: 0.45em;
  }
}

@media only screen and (max-width: 480px) and (max-height: 240px) {
  .debt-clock-container {
    padding-top: 0px;
    padding-bottom: 12px;
  }
}
