:root {
  --HEADER_FONT_SIZE: 56px;
  --SUB_HEADER_FONT_SIZE: 28px;
  --PRIMARY_RED: #e3111d;
}

/* 
  NOTE: App is specifically styled for LED screen resolution provided by Adtronics (except width 1680px)
  LED screen resolutions: 
  960w x 480h pixels
  704w x 480h pixels
  480w x 320h pixels
  480w x 240h pixels
*/

@media only screen and (max-width: 1680px) {
  :root {
    --HEADER_FONT_SIZE: 2.92vw;
    --SUB_HEADER_FONT_SIZE: 1.46vw;
  }
}

@media only screen and (max-width: 960px) {
  :root {
    --HEADER_FONT_SIZE: 30px;
    --SUB_HEADER_FONT_SIZE: 16px;
  }
}

@media only screen and (max-width: 740px) {
  :root {
    --HEADER_FONT_SIZE: 22px;
    --SUB_HEADER_FONT_SIZE: 16px;
  }
}

@media only screen and (max-width: 640px) {
  :root {
    --HEADER_FONT_SIZE: 17px;
    --SUB_HEADER_FONT_SIZE: 12px;
  }
}

@media only screen and (max-width: 480px) and (max-height: 320px) {
  :root {
    --HEADER_FONT_SIZE: 15.5px;
    --SUB_HEADER_FONT_SIZE: 11px;
  }
}

@media only screen and (max-width: 480px) and (max-height: 240px) {
  :root {
    --HEADER_FONT_SIZE: 13px;
    --SUB_HEADER_FONT_SIZE: 11px;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: white;
}
